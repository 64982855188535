import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "./detail.css";

import Motif_left from '../images/service/motif_left.png'
import Motif_right from '../images/service/motif_right.png'

import Policy from '../components/common/policy';

const Detail2 = () => {
  return (
    <Layout>
      <SEO title="おなまえ公認コーチ" />
      <div className="detail-page">
        <div className="wrapper">
          <div className="title ff-min">
            <h4>jump up</h4>
            <h2>
              <span>
                おなまえメソッド
                <span className="registerd-sign">&#174;</span>
              </span>
              <span>公認講師養成講座</span>
            </h2>
            <div>
              <span>
               「おなまえメソッド
                <span className="registerd-sign">&#174;</span>
                」全てのトレーニング
              </span>
              <span>認定養成講座を開催できます。</span>
            </div>
            <div>
              <span>創始者星名ミリーが直接お伝えする講座です。</span>
            </div>
          </div>
          <div className="detail-title">
            <ul>
              <li className="image">
                <img alt=" " src={Motif_left} className="scale-to-fit" />
              </li>
              <li className="text ff-min">
                <span>養成内容</span>
              </li>
              <li className="image">
                <img alt=" " src={Motif_right} className="scale-to-fit" />
              </li>
            </ul>
          </div>
          <div className="information ff-min">
            <ul>
              <li>
                全10時間<br />
                2日間集中コース5h/1回 or 5回ゆったりコース2h/1回<br />
                受講費：484,000円（税込）
              </li>
            </ul>
            <div className="benefits">
              <h4>&mdash;特典&mdash;</h4>
              <p>
                創始者の星名ミリー個別コンサル1回（60分）
              </p>
              <p>
                授業を動画で毎回配信
              </p>
              <p>
                講座期間中にFaceBookグループでフォロー
              </p>
              <p>
                おなまえメソッド
                <span className="registerd-sign">&#174;</span>
                オンラインサロンご招待
              </p>
            </div>
          </div>
          <div className="read">
            <span>授業を動画で毎回配信</span>
            <span>考え方が軽やかになり、スルスル人生が上手くいく！</span>
            <span>星名ミリーが提唱している、</span>
            <span>「自分を惚れ、周りにも惚れる」他者と比べることなく自分の基準で生きる</span>
            <span>考え方や、内容を完全に理解し、実践するのがスタンダードにします。</span>
          </div>
          <div className="read">
            <span>これまでの学びのまとめの講座です。実践的に落とし込んでいきます。</span>
          </div>
          <div className="read">
            <span>講師の方がまずは自分にどんどん惚れ、自分に戻り、自分に感動する</span>
            <span>益々人にインスパイア与えるくらいの「輝く自分」になりましょう。</span>
          </div>
          <div className="read">
            <span>名前の大きな影響力を味方にして多くの人の人生を変えられる「私」に</span>
            <span>「あなたのおかげで人生が楽しく自分が大好きになりました」</span>
            <span>と言われる側になり輝く人を増やしこの世界を輝かせてください。</span>
          </div>
          <div className="read">
            <span>一緒に、「”私たち”は世界一愛おしい♡」を体現していきましょう。</span>
          </div>
          <Policy />
          <div className="btn-holder">
            <a href="mailto:hello.millyhoshina@gmail.com?subject=おなまえメソッド®公認講師養成講座申込み">講座に申し込む</a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Detail2
